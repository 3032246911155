import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

class Training extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <SEO title={"Training"} titleDesc={"-"} titleKeywords={"-"} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

        {/* top section */}
        <section role="top--section" className="pb-5">
          <article className="container">
            <Row className="align-items-center mt-5">
              <Col lg={12} className="text-center">
                <h1 className="font-weight-bold">Fuse Classroom Video Guide</h1>
                <p className="h5 font-weight-normal mt-3">
                  Learn everything you need to know about being an instructor, students or school administrator.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center mt-5">
              <Col md={4} className="my-3">
                <a href="/training/admin" className="resources-block">
                  <img width="auto" height="60" src="/images/admin.svg" alt="admin" />
                  <div className="h4">I’m an Admin</div>
                </a>
              </Col>

              <Col md={4} className="my-3">
                <a href="/training/parent" className="resources-block">
                  <img width="auto" height="60" src="/images/parent.svg" alt="parent" />
                  <div className="h4">I’m an Parent</div>
                </a>
              </Col>

              <Col md={4} className="my-3">
                <a href="/training/instructor" className="resources-block">
                  <img width="auto" height="60" src="/images/instructor.svg" alt="instructor" />
                  <div className="h4">I’m an Instructor</div>
                </a>
              </Col>

              <Col md={4} className="my-3">
                <Link to="/training/student" className="resources-block">
                  <img width="auto" height="60" src="/images/student.svg" alt="student" />
                  <div className="h4">I’m a Student</div>
                </Link>
              </Col>

              <Col md={4} className="my-3">
                <Link to="/training/junior" className="resources-block">
                  <img width="auto" height="60" src="/images/junior-student.svg" alt="student" />
                  <div className="h4">I’m a Junior Student</div>
                </Link>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}
      </Layout>
    )
  }
}

export default Training
